/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import './styles/app.scss';
import './styles/navbar.scss';
import './styles/shortcut.scss';
import './styles/footer.scss';
import './styles/home.scss';


import './styles/owlcarousel/owl.carousel.min.css';
import './styles/owlcarousel/owl.theme.default.css';



// start the Stimulus application
import './bootstrap';


import * as bootstrap from 'bootstrap'
/* Make autoselect use bootstrap */
import 'tom-select/dist/css/tom-select.bootstrap5.css';